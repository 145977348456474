.Chart_wrapper {
    min-height: 440px;
}

.Chart_loader {
    background-color: white;
    /* width: 100%;
    height: 100%; */
}

/* .Assets_loader div {
    margin: 200px auto;
}

.Assets_loader div svg {
    background: #eceff1;
} */
