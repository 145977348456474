.btn {
    display: inline-block;
    background: #000;
    color: #fff;
    border: none;
    padding: 10px 20px;
    /* margin: 5px; */
    margin-bottom: 20px;
    margin-left: 10px;
    margin-top: 10px;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    font-size: 12px; /* changed 2022-02-21 */
    font-family: inherit;
}

.btn:focus {
    outline: none;
}

.btn:active {
    transform: scale(0.98);
}

.btnBlock {
    display: block;
    width: 100%;
}

/* From academind: https://www.youtube.com/watch?v=Dorf8i6lCuk */
/* 
.btn {
    font: inherit;
    padding: 0.5rem 1.5rem;
    cursor: pointer;
    border-radius: 4px;
    background-color: #800040;
    color: white;
    border: 1px solid #800040;
    margin: 0 1rem;
}

.btn:hover {
    background-color: #9c1458;
    border-color: #9c1458;
}

.btnAlt {
    background-color: transparent;
    color: #800040;
}

.btnAlt:hover {
    background-color: #f8dae9;
} */
