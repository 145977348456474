@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;800&display=swap);
html {
    --mainColor1: #394855;
    --mainColor2: #9dadbc;
    --mainColor3: #8d99ae;
    --mainColor4: #cfd8dc;
    --mainColor5: #eceff1;
    --mainColor6: #c0c0c0;
    --mainColor7: #696969;
    --mainFontColor: #111111;
    --mainBgColor: white;
    --mainFontFamily1: "Raleway", "sans-serif";
    --mainFontFamily2: "Raleway", "sans-serif";
    --mainModalBgColor: rgb(10, 122, 173);
    --mainModalFontColor: white;
}

/*
Analytics Design Guidelines

    Added by Mikko Karppinen, last edited by Mikko Karppinen on Oct 22, 2021
*/

/*
COLORS
------

PAGE BACKGROUND: #ECEFF1

Graphics Set 1: 0a7aad, 429abd, 5faac5, 7ebacc, 72c3cf, 66ccdd

Set 2: 394855, 9dadbc, 8d99ae, cfd8dc, eceff1, c0c0c0, 696969

Set 3: 8d68b2, ab5ea4, c2558e
*/

body {
    /* font-family: sans-serif; */
    /* margin: 3rem; */
    margin: 0;
    background-color: #eceff1;
}

.set1 {
    color: #0a7aad;
    color: #429abd;
    color: #5faac5;
    color: #7ebacc;
    color: #72c3cf;
    color: #66ccdd;
}

.set2 {
    color: #394855;
    color: #9dadbc;
    color: #8d99ae;
    color: #cfd8dc;
    color: #eceff1;
    color: #c0c0c0;
    color: #696969;
}

.set3 {
    color: #8d68b2;
    color: #ab5ea4;
    color: #c2558e;
}

/*
Fonts and headers
-----------------

Heading fonts:

Raleway

#111111
*/

/* https://fonts.google.com/specimen/Raleway?query=raleway */

body {
    font-family: "Raleway", "sans-serif";
    /* margin: 3rem; */
    margin: 0;
    /* background-color: #eceff1; */
    background-color: #f4f6f8;
}

h1 {
    font-family: "Raleway", "sans-serif";
    font-size: 24px; /* changed 2022-02-21 */
    /* font-style: normal;
    font-variant: normal;
    font-weight: 700;
    line-height: 26.4px; */
    color: #111111;
    margin-bottom: 8px;
}
h2 {
    font-family: "Raleway", "sans-serif";
    font-size: 18px; /* changed 2022-02-21 */
    /* font-style: normal;
    font-variant: normal;
    font-weight: 700;
    line-height: 26.4px; */
    color: #111111;
    margin-bottom: 8px;
    font-weight: 500;
}
h3 {
    font-family: "Raleway", "sans-serif";
    font-size: 14px;
    /*font-style: normal;
    font-variant: normal;
    font-weight: 700;
    line-height: 15.4px; */
    color: #111111;
    margin-bottom: 8px;
}
p {
    font-family: "Raleway", "sans-serif";
    /* font-size: 14px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 20px; */
    color: #111111;
}
blockquote {
    font-family: "Raleway", "sans-serif";
    /* font-size: 21px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 30px; */
    color: #111111;
}
pre {
    font-family: "Raleway", "sans-serif";
    /* font-size: 13px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 18.5667px; */
    color: #111111;
}

/* used in GenericChart for axis fonts */
text {
    font-family: "Raleway", "sans-serif";
    font-size: 10px;
    /*font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 18.5667px; */
    /* color: #111111; */
}

/*
Metrics
-------

Metric font:

Raleway

#111111

Metric name:

Raleway

#394855
*/

.metricFont {
    font-family: "Raleway", "sans-serif";
    color: #111111;
}

.metricName {
    font-family: "Raleway", "sans-serif";
    color: #394855;
}

/*
Selector and dropdowns
----------------------

Raleway

#696969
*/

/*
Paragraph and texts
-------------------

Raleway

#272727
*/

.ValueCard_resultItem__1RCXW {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
}

.ValueCard_resultItem__1RCXW:not(:last-of-type) {
    border-right: #394855 1px solid;
}

.ValueCard_resultItemTitle__1-h6A {
    font-size: 12px; /* changed 2022-02-21 */
    /*font-weight: 600;*/
    color: #394855;
    margin-bottom: 2%;
}

.ValueCard_amountNumber__2nnIK {
    font-family: "Raleway", sans-serif;
    font-size: 30px; /* changed 2022-02-21 */
    font-weight: 800;
}

.ValueCard_loader__2ZH_K {
    height: 40px; /* changed 2022-02-21 */
}

.GenericChart_Chart_wrapper__3flY6 {
    min-height: 440px;
}

.GenericChart_Chart_loader__1jVpu {
    background-color: white;
    /* width: 100%;
    height: 100%; */
}

/* .Assets_loader div {
    margin: 200px auto;
}

.Assets_loader div svg {
    background: #eceff1;
} */

.Button_btn__3SAfG {
    display: inline-block;
    background: #000;
    color: #fff;
    border: none;
    padding: 10px 20px;
    /* margin: 5px; */
    margin-bottom: 20px;
    margin-left: 10px;
    margin-top: 10px;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    font-size: 12px; /* changed 2022-02-21 */
    font-family: inherit;
}

.Button_btn__3SAfG:focus {
    outline: none;
}

.Button_btn__3SAfG:active {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
}

.Button_btnBlock__2qy_e {
    display: block;
    width: 100%;
}

/* From academind: https://www.youtube.com/watch?v=Dorf8i6lCuk */
/* 
.btn {
    font: inherit;
    padding: 0.5rem 1.5rem;
    cursor: pointer;
    border-radius: 4px;
    background-color: #800040;
    color: white;
    border: 1px solid #800040;
    margin: 0 1rem;
}

.btn:hover {
    background-color: #9c1458;
    border-color: #9c1458;
}

.btnAlt {
    background-color: transparent;
    color: #800040;
}

.btnAlt:hover {
    background-color: #f8dae9;
} */

.ModalDateRangePicker_actions__1N1ku {
    text-align: right;
}

.ModalDateRangePicker_modalDateRangePicker__MSMoX {
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
    border-radius: 6px;
    background-color: white;
    padding: 1rem;
    text-align: center;
    width: 40rem;
    left: calc(50% - 20rem);
    height: 28rem;
    z-index: 10;
    position: fixed;
    top: 20vh;
}

.Backdrop_backdrop__3W7jc {
    position: fixed;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.75);
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
}

.SelectedDateRangeHeading_periodName__2z1MF {
    /*font-size: 12px;*/ /* changed 2022-02-21 */
    /*font-weight: 600;*/
    /*color: #394855;*/
    margin-top: 2px; /* changed 2022-02-21 */
    margin-bottom: 2px; /* changed 2022-02-21 */
}

.SelectedDateRangeHeading_dateRange__3DSgZ {
    font-size: 10px;
    margin-top: 2px; /* changed 2022-02-21 */
    margin-bottom: 5px; /* changed 2022-02-21 */
}

.CDNAnalyticsDashboard_actions__3_JSI {
    text-align: right;
}

.CDNAnalyticsDashboard_select__1Yp7R {
    float: right;
    padding: 8px 0px 0px 8px;
    cursor: pointer;
    font-size: 14px;
    font-family: inherit;
    text-align: left;
    width: 12rem;
}

/* was .content-wrapper */
.CDNAnalyticsDashboard_container__1Cntx {
    width: 100%;
    /* width: 960px; */
    /* overflow-x: hidden;*/
    /*overflow-y: auto; */

    /* max-width: 500px;
    margin: 30px auto;
    overflow: auto;
    min-height: 300px;
    border: 1px solid steelblue;
    padding: 30px;
    border-radius: 5px; */
}

.CDNAnalyticsDashboard_view__Nw57h {
    width: 95%;
    margin: 0 auto;
    /*height: 100%; */
    /* overflow: auto; */
}

.CDNAnalyticsDashboard_overviewWrapper1__2cnnW {
    display: grid;
    /* grid-template-columns: 20% 20% 20% 20% 20%; */
    grid-template-columns: 25% 25% 25% 25%;
    /* grid-template-columns: 33% 33% 33%; */
    /* grid-template-columns: 50% 50%; */
    background-color: white;
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
    padding: 1%;
    font-family: "Raleway", "sans-serif";
    border-radius: 0.5rem;
}

.CDNAnalyticsDashboard_overviewWrapper12__PWL00 {
    display: grid;
    grid-template-columns: 50% 50%;
    background-color: white;
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
    padding: 0.5%;
    font-family: "Raleway", "sans-serif";
    border-radius: 0.5rem;
    /* margin-bottom: 0.5rem; */
}

.CDNAnalyticsDashboard_overviewWrapper13__rvV-K {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    background-color: white;
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
    padding: 0.5%;
    font-family: "Raleway", "sans-serif";
    border-radius: 0.5rem;
    /* margin-bottom: 0.5rem; */
}

.CDNAnalyticsDashboard_overviewWrapper14__1H162 {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    background-color: white;
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
    padding: 0.5%;
    font-family: "Raleway", "sans-serif";
    border-radius: 0.5rem;
    /* margin-bottom: 0.5rem; */
}

.CDNAnalyticsDashboard_overviewWrapper15__3vsm3 {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    background-color: white;
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
    padding: 0.5%;
    font-family: "Raleway", "sans-serif";
    border-radius: 0.5rem;
    margin-bottom: 1rem;
}

.CDNAnalyticsDashboard_overviewWrapper16__7maLB {
    display: grid;
    grid-template-columns: 16.6% 16.6% 16.6% 16.6% 16.6% 16.6%;
    background-color: white;
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
    padding: 0.5%;
    font-family: "Raleway", "sans-serif";
    border-radius: 0.5rem;
    margin-bottom: 1rem;
}

.CDNAnalyticsDashboard_overviewWrapper2__3x6BH {
    font-family: "Raleway", "sans-serif";
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
    font-size: 18px;
    /*gap: 20px;*/
}

.CDNAnalyticsDashboard_overviewWrapper2__3x6BH > * + * {
    margin-left: 20px;
}

.CDNAnalyticsDashboard_overviewWrapper3__2KKht {
    /* display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%; */
    background-color: white;
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
    /* padding: 2%; */
    /* padding-top: 5px; */
    /* padding-top: 5px; */
    /* padding-top: 5px; */
    /* padding-top: 5px; */
    font-family: "Raleway", "sans-serif";

    border-radius: 0.5rem;
}

.CDNAnalyticsDashboard_resultItem3__21vCy {
    width: 90%;

    background: white;
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
    padding: 2rem 2rem 2rem 2rem;

    /* margin-top: 20px; */
    margin: 0 1% 1% 1%;
}

/* search input */
.CDNAnalyticsDashboard_input__2rEcO {
    /* display: inline-block; */
    /* background: #000; */
    /* color: #fff; */
    /* border: none; */
    padding: 8px 16px;
    /* margin: 5px; */
    margin-bottom: 20px;
    margin-left: 10px;
    margin-top: 10px;
    margin-right: 10px;
    border-radius: 5px;
    /* cursor: pointer; */
    text-decoration: none;
    font-size: 12px; /* changed 2022-02-21 */
    font-family: inherit;
}

svg {
    background: white;
    /* for stuff such as the axis to show below the graph */
    overflow: visible;
    display: block;
    width: 100%;
}

/* #root {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    height: 100vh;
    padding: 0 50px;
} */

.CDNAnalyticsDashboard_svgButton__nXPyB {
    width: 100px;
    border: 1px solid black;
    margin: 0 auto 5px;
}

