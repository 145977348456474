.actions {
    text-align: right;
}

.modalDateRangePicker {
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
    border-radius: 6px;
    background-color: white;
    padding: 1rem;
    text-align: center;
    width: 40rem;
    left: calc(50% - 20rem);
    height: 28rem;
    z-index: 10;
    position: fixed;
    top: 20vh;
}
