.periodName {
    /*font-size: 12px;*/ /* changed 2022-02-21 */
    /*font-weight: 600;*/
    /*color: #394855;*/
    margin-top: 2px; /* changed 2022-02-21 */
    margin-bottom: 2px; /* changed 2022-02-21 */
}

.dateRange {
    font-size: 10px;
    margin-top: 2px; /* changed 2022-02-21 */
    margin-bottom: 5px; /* changed 2022-02-21 */
}
